body {
  font-family: Neucha;
}

/* react-slideshow library */

.react-slideshow-container .default-nav:first-of-type {
  margin-left: 20px !important;
  position: absolute;
  /* top: 50%; */
  padding: 0;
}

.react-slideshow-container .default-nav:last-of-type {
  margin-right: 20px !important;
  right: 0;
  position: absolute;
  /* top: 50%; */
  padding: 0;
}

.each-fade {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 450px;
}

.each-fade > div {
  width: 100%;
}

.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* */

/* loading components */

.jKLeey {
  margin-top: 80px !important;
  width: 28px !important;
  height: 28px !important;
}

/* */

.errorAlert {
  font-size: 22px;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  height: 0;
  padding: 13px 0;
  text-align: center;
  color: #ffffff;
  background: #f44336;
}

.errorAlert.displayed {
  opacity: 1;
  visibility: visible;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.errorAlert.animated-hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}


.successToast {
  font-size: 22px;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  height: 0;
  padding: 13px 0;
  text-align: center;
  color: #ffffff;
  background: #6abb80;
}

.successToast.displayed {
  opacity: 1;
  visibility: visible;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.successToast.animated-hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

ul {
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: top;
}

h4 {
  font-weight: 400;
  margin: 0;
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(assets/fonts/Raleway-VariableFont_wght.ttf) format("opentype");
}

@font-face {
  font-family: "OrelegaOne";
  src: local("OrelegaOne-Regular"),
    url(assets/fonts/OrelegaOne-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Lobster";
  src: local("Lobster-Regular"),
    url(assets/fonts/Lobster-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Neucha";
  src: local("Neucha-Regular"),
    url(assets/fonts/Neucha-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Pacifico";
  src: local("Pacifico-Regular"),
    url(assets/fonts/Pacifico-Regular.ttf) format("opentype");
}

@media screen and (max-width: 970px) {
  .each-fade {
    max-height: max-content;
  }
}

@media screen and (max-width: 768px) {
  .react-slideshow-container .default-nav:first-of-type {
    margin-right: -32px !important;
  }
  .react-slideshow-container .default-nav:last-of-type {
    margin-left: -32px !important;
  }
}

@media screen and (max-width: 670px) {
  .jKLeey {
    margin-top: 60px !important;
    width: 20px !important;
    height: 20px !important;
  }
  .each-fade {
    height: calc(100vh - 350px);
    max-height: none;
  }
}

@media screen and (max-width: 570px) {
  .each-fade {
    height: calc(100vh - 170px);
    max-height: none;
  }
}

@media screen and (max-width: 320px) {
  .each-fade {
    height: calc(100vh - 70px);
  }
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}
